import React from 'react';
import { Typography, Box, List, ListItem, ListItemText } from '@mui/material';

function Terms() {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5">
        AI Diary Terms of Service
      </Typography>

      <Typography variant="body1" mb={2} mt={2}>
        Welcome to AI Diary! Your private space for personal reflection and growth. Before you begin, please take a moment to read our Terms of Service. By using AI Diary, you agree to these terms, which ensure a secure and beneficial experience.
      </Typography>

      <Typography variant="h6" mb={2}>
        1. Acceptance of Terms
      </Typography>
      <Typography variant="body1" mb={2}>
        By accessing or using AIDiary.us ("the Site"), operated by Wandering Ghost, LLC, you agree to be bound by these Terms of Service ("Terms"). If you do not agree with any of these Terms, please do not use the Site.
      </Typography>

      <Typography variant="h6" mb={2}>
        2. Your Use of AI Diary
      </Typography>
      <Typography variant="body1" mb={0}>
        AI Diary is designed for personal use. You are free to:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="- Create private journal entries." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Use AI-powered tools to enhance your journaling experience." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Customize your journaling environment." />
        </ListItem>
      </List>
      <Typography variant="body1">
        To maintain the integrity of the service, you agree not to:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="- Use the Site for any illegal activities." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Attempt to access other users' private information." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Disrupt or interfere with the security or functionality of the Site." />
        </ListItem>
      </List>

      <Typography variant="h6" mb={2}>
        3. Your Content
      </Typography>
      <Typography variant="body1" mb={2}>
        Your journal entries are private and belong to you. We do not claim ownership over your content. We only access your entries to provide and improve our services, and we will never share or sell your personal journal content.
      </Typography>

      <Typography variant="h6" mb={2}>
        4. Privacy and Security
      </Typography>
      <Typography variant="body1" mb={2}>
        We take your privacy seriously. Your journal entries are encrypted and stored securely. We implement industry-standard security measures to protect your data. For more details, please refer to our Privacy Policy.
      </Typography>

      <Typography variant="h6" mb={2}>
        5. Account Security
      </Typography>
      <Typography variant="body1" mb={2}>
        You are responsible for maintaining the confidentiality of your AI Diary account. Keep your login information secure and notify us immediately if you suspect unauthorized use of your account.
      </Typography>

      <Typography variant="h6" mb={2}>
        6. Termination
      </Typography>
      <Typography variant="body1" mb={2}>
        Wandering Ghost, LLC reserves the right to suspend or terminate any account that violates these Terms or for any reason, at its discretion.
      </Typography>

      <Typography variant="h6" mb={2}>
        7. Changes to Terms
      </Typography>
      <Typography variant="body1" mb={2}>
        We may update these Terms from time to time. If we do, we'll notify you of any changes. Continued use of the Site after changes means you accept the new Terms.
      </Typography>

      <Typography variant="h6" mb={2}>
        8. Liability Disclaimer
      </Typography>
      <Typography variant="body1" mb={2}>
        AI Diary is provided "as is," and Wandering Ghost, LLC makes no warranties or guarantees about the availability or quality of the service. We are not liable for any content shared on the Site, user interactions, or disruptions in service.
      </Typography>

      <Typography variant="h6" mb={2}>
        9. Contact Information
      </Typography>
      <Typography variant="body1" mb={2}>
        AI Diary is created and operated by Wandering Ghost, LLC. For any questions or concerns, you can contact us at:
      </Typography>
      <Typography variant="body1" mb={2}>
        Wandering Ghost, LLC<br />
        PO Box 362<br />
        Montague, MI, 49437<br />
        Email: wanderingghostllc@gmail.com
      </Typography>

      <Typography variant="h6" mb={2}>
        10. Governing Law
      </Typography>
      <Typography variant="body1" mb={2}>
        These Terms are governed by the laws of the state of Michigan, without regard to its conflict of law principles.
      </Typography>

      <Typography variant="body1" mb={2}>
        By using AI Diary, you're agreeing to all the above. We hope you enjoy your private journaling experience!
      </Typography>
    </Box>
  );
}

export default Terms;
