import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Paper,
  CircularProgress,
  Button,
  List,
  ListItem,
  ListItemText,
  Grid,
  Link,
  Skeleton,
} from "@mui/material";
import {
  doc,
  getDoc,
  collection,
  query,
  orderBy,
  getDocs,
  limit,
  addDoc,
  setDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./components/AuthContext";
import OpenAI from "openai";
import { styled } from "@mui/material/styles";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import TimelineIcon from "@mui/icons-material/Timeline";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AssessmentIcon from "@mui/icons-material/Assessment";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SummarizeIcon from "@mui/icons-material/Summarize";

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "auto",
  padding: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
}));

function Dashboard({ user, storage, db }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [subscriptionLevel, setSubscriptionLevel] = useState(0);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(true);
  const [entries, setEntries] = useState([]);
  const [latestInsight, setLatestInsight] = useState("");
  const [latestLookingAhead, setLatestLookingAhead] = useState("");
  const [profileStats, setProfileStats] = useState(null);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });
  const [showAllEntries, setShowAllEntries] = useState(false);
  const [insightsLoading, setInsightsLoading] = useState(false);

  // Function to generate title using OpenAI
  const generateTitle = async (content) => {
    try {
      const response = await openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: [
          {
            role: "system",
            content:
              "You are a helpful assistant that generates short, catchy titles for journal entries in the language of the user.",
          },
          {
            role: "user",
            content: `Generate a short title for this journal entry, using no quotes around it: ${content}`,
          },
        ],
        max_tokens: 50,
      });
      return response.choices[0].message.content.trim();
    } catch (error) {
      console.error("Error generating title:", error);
      return "";
    }
  };

  useEffect(() => {
    fetchUserData();
    fetchEntries();
    fetchLatestInsight();
    fetchProfileStats();
  }, []);

  const fetchUserData = async () => {
    try {
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        setSubscriptionLevel(userData.subscriptionLevel || 0);
        setUsername(userData.username || "");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setErrorMessage("Failed to load user data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const fetchEntries = async () => {
    try {
      if (currentUser) {
        const entriesRef = collection(db, "users", currentUser.uid, "entries");
        const q = query(entriesRef, orderBy("createdAt", "desc"));
        const querySnapshot = await getDocs(q);
        const fetchedEntries = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Generate titles for entries that don't have them
        const updatedEntries = await Promise.all(
          fetchedEntries.map(async (entry) => {
            if (!entry.title && entry.content) {
              const generatedTitle = await generateTitle(entry.content);
              if (generatedTitle) {
                // Update the entry in Firestore
                const entryRef = doc(db, "users", currentUser.uid, "entries", entry.id);
                await setDoc(entryRef, { title: generatedTitle }, { merge: true });
                return { ...entry, title: generatedTitle };
              }
            }
            return entry;
          })
        );

        setEntries(updatedEntries);
        await fetchLatestInsight();
      }
    } catch (error) {
      console.error("Error fetching entries:", error);
      setErrorMessage("Failed to load entries. Please try again later.");
    }
  };

  const generateInsights = async (content) => {
    try {
      // Use the appropriate base URL depending on your environment
      const baseUrl =
        process.env.NODE_ENV === "development"
          ? "http://localhost:3007" // Update this port if different
          : "";

      const response = await fetch(`${baseUrl}/api/generate-insights`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ content }),
      });

      if (!response.ok) {
        const errorData = await response.text();
        console.error("API Error Response:", errorData);
        throw new Error(`Failed to generate insights: ${errorData}`);
      }

      const data = await response.json();
      console.log("Successfully generated insights:", data);
      return data;
    } catch (error) {
      console.error("Error generating insights:", error);
      throw error; // Re-throw to be handled by the calling function
    }
  };

  const fetchLatestInsight = async () => {
    try {
      if (currentUser) {
        const insightsRef = collection(
          db,
          "users",
          currentUser.uid,
          "insights"
        );
        const q = query(insightsRef, orderBy("generatedAt", "desc"), limit(1));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const latestInsightDoc = querySnapshot.docs[0];
          const insightData = latestInsightDoc.data();
          setLatestInsight(insightData.insights || "No insights available.");
          setLatestLookingAhead(
            insightData.lookingAhead || "No looking ahead available."
          );
        } else {
          setLatestInsight("No insights available.");
          setLatestLookingAhead("No looking ahead available.");
        }
      }
    } catch (error) {
      console.error("Error fetching latest insight:", error);
      setLatestInsight("Failed to load insights. Please try again later.");
      setLatestLookingAhead(
        "Failed to load looking ahead. Please try again later."
      );
    }
  };

  const fetchProfileStats = async () => {
    try {
      if (currentUser) {
        const profileRef = doc(
          db,
          "users",
          currentUser.uid,
          "profile",
          "weekly"
        );
        const profileSnap = await getDoc(profileRef);

        if (profileSnap.exists()) {
          setProfileStats(profileSnap.data());
        } else {
        }
      }
    } catch (error) {
      console.error("Error fetching profile stats:", error);
    }
  };

  const renderStars = (score) => {
    if (score === undefined || score === null) return "❓";
    const starCount = Math.min(Math.max(Math.round(score / 20), 1), 5);
    return "⭐".repeat(starCount);
  };

  const renderProfileStats = () => {
    if (!profileStats) return null;

    const stats = [
      { name: "Mood", value: profileStats.mood },
      { name: "Vitality", value: profileStats.vitality },
      { name: "Resilience", value: profileStats.resilience },
      { name: "Social Connections", value: profileStats.socialConnections },
      { name: "Personal Growth", value: profileStats.personalGrowth },
      { name: "Stress Management", value: profileStats.stressManagement },
    ];

    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <AssessmentIcon sx={{ fontSize: 28, mr: 2, color: "primary.main" }} />
            Your Statistics
          </Box>
        </Typography>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ display: "block", mb: 3 }}
        >
          Updates every Monday
        </Typography>
        <Grid container spacing={2}>
          {stats.map((stat, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper
                sx={{ p: 2, textAlign: "center", minHeight: 250 }}
                elevation={1}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  {stat.name}
                </Typography>
                <Typography variant="h4" sx={{ my: 1 }}>
                  {renderStars(stat.value?.score)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {stat.value?.score}/100
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {stat.value?.explanation}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: 5 }}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 600, mt: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormatListBulletedIcon sx={{ fontSize: 28, mr: 2, color: "primary.main" }} />
              Top 3 Recurring Themes
            </Box>
          </Typography>
          <List dense>
            {profileStats.recurringThemes?.map((theme, index) => (
              <ListItem key={index} sx={{ py: 0.5 }}>
                <ListItemText
                  primary={theme}
                  primaryTypographyProps={{
                    variant: "body2",
                    sx: { display: "list-item", listStyleType: "disc", ml: 2 },
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 600, mt: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <SummarizeIcon sx={{ fontSize: 28, mr: 2, color: "primary.main" }} />
              Overall Summary
            </Box>
          </Typography>
          <Typography variant="body2" sx={{ lineHeight: 1.8 }}>
            {profileStats.overallSummary}
          </Typography>
        </Box>
      </Box>
    );
  };

  const handleCreateEntry = () => {
    navigate("/new");
  };

  const handleEntryClick = (entryId) => {
    navigate(`/entry/${entryId}`);
  };

  const generateInsightsForEntry = async (entryId, content) => {
    try {
      setInsightsLoading(true);
      console.log(`Generating insights for entry ${entryId}`);

      const insights = await generateInsights(content);

      if (insights) {
        const insightsRef = collection(
          db,
          "users",
          currentUser.uid,
          "insights"
        );
        await addDoc(insightsRef, {
          entryId: entryId,
          insights: insights.insights,
          lookingAhead: insights.lookingAhead,
          generatedAt: new Date(),
        });
        await fetchLatestInsight();
      }
    } catch (error) {
      console.error(`Error generating insights for entry ${entryId}:`, error);
    } finally {
      setInsightsLoading(false);
    }
  };

  const handleShowAllEntries = () => {
    setShowAllEntries(true);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        pb: 4,
        position: "relative",
        zIndex: 1,
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          fontWeight: 700,
          color: (theme) => theme.palette.primary.main,
          mb: 2,
        }}
      >
        Hello! It's {new Date().toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric'
        })} at {new Date().toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        }).toLowerCase()}
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <StyledPaper elevation={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "flex-start", md: "center" },
                justifyContent: { xs: "flex-start", md: "space-between" },
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: { xs: 2, md: 0 },
                }}
              >
                <AutoStoriesIcon
                  sx={{ fontSize: 28, mr: 2, color: "primary.main" }}
                />
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  Today's Entry
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateEntry}
                sx={{
                  py: 1.5,
                  px: 4,
                }}
              >
                Open Today's Entry
              </Button>
            </Box>
          </StyledPaper>

          <Box sx={{ mt: 3 }}>
            <Paper sx={{ p: 5, mb: 2 }} elevation={0}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { xs: "flex-start", sm: "center" },
                  justifyContent: "space-between",
                  gap: { xs: 1, sm: 0 },
                  mb: 2,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <TimelineIcon
                    sx={{ fontSize: 28, mr: 2, color: "primary.main" }}
                  />
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    AI Insights
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  Updates nightly or{" "}
                  <Link
                    component="button"
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={async () => {
                      if (entries.length > 0) {
                        const latestEntry = entries[0];
                        await generateInsightsForEntry(
                          latestEntry.id,
                          latestEntry.content
                        );
                      }
                    }}
                    disabled={insightsLoading || entries.length === 0}
                  >
                    force a refresh
                  </Link>
                </Typography>
              </Box>
              {insightsLoading ? (
                <>
                  <Skeleton variant="text" sx={{ fontSize: "1rem", mb: 1 }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem", mb: 1 }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem", mb: 1 }} />
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: 600, mt: 3 }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <TrendingUpIcon sx={{ fontSize: 28, mr: 2, color: "primary.main" }} />
                      Looking ahead
                    </Box>
                  </Typography>
                  <Skeleton variant="text" sx={{ fontSize: "1rem", mb: 1 }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem", mb: 1 }} />
                </>
              ) : (
                <>
                  <Typography variant="body1">{latestInsight}</Typography>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: 600, mt: 3 }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <TrendingUpIcon sx={{ fontSize: 28, mr: 2, color: "primary.main" }} />
                      Looking ahead
                    </Box>
                  </Typography>
                  <Typography variant="body1">{latestLookingAhead}</Typography>
                </>
              )}
              {renderProfileStats()}
            </Paper>
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <StyledPaper elevation={2}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: 600, mb: 0 }}
            >
              Previous Entries
            </Typography>
            <List
              sx={{
                maxHeight: "600px",
                overflowY: "auto",
                cursor: "pointer",
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "rgba(0,0,0,0.2)",
                  borderRadius: "4px",
                },
              }}
            >
              {entries
                .slice(0, showAllEntries ? entries.length : 20)
                .map((entry) => (
                  <ListItem
                    key={entry.id}
                    button
                    onClick={() => handleEntryClick(entry.id)}
                    sx={{
                      borderRadius: 1,
                      mb: 0.5,
                      "&:hover": {
                        backgroundColor: "rgba(0,0,0,0.04)",
                      },
                    }}
                  >
                    <ListItemText
                      primary={new Date(
                        entry.createdAt.toDate()
                      ).toLocaleDateString()}
                      secondary={entry.title || "Untitled"}
                      primaryTypographyProps={{
                        fontWeight: 600,
                        color: "primary.main",
                      }}
                    />
                  </ListItem>
                ))}
            </List>
            {!showAllEntries && entries.length > 20 && (
              <Button
                onClick={handleShowAllEntries}
                sx={{ mt: 2 }}
                fullWidth
                variant="outlined"
              >
                Show All
              </Button>
            )}
          </StyledPaper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard;
