import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { Route, Routes, Link, Navigate } from "react-router-dom";
import {
  Button,
  Typography,
  Container,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  CircularProgress,
} from "@mui/material";
import LoginButton from "./components/LoginButton";
import Dashboard from "./Dashboard";
import Settings from "./Settings";
import PaymentSuccess from "./PaymentSuccess";
import Footer from "./Footer";
import Terms from "./Terms";
import Privacy from "./Privacy";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import Home from "./Home";
import Entry from "./Entry";
import { HelmetProvider } from "react-helmet-async";
import { alpha } from '@mui/material/styles';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { createTheme, ThemeProvider } from '@mui/material';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const db = getFirestore(app);

// Add this after firebaseConfig
const theme = createTheme({
  palette: {
    primary: {
      main: '#2c3e50',
      light: '#34495e',
    },
    secondary: {
      main: '#e74c3c',
    },
    background: {
      default: '#f5f6fa',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: "'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          color: '#2c3e50',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          fontWeight: 600,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        },
      },
    },
  },
});

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  if (loading) {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.palette.background.default
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          backgroundColor: theme.palette.background.default
        }}>
          <AppBar position="static" elevation={0}>
            <Toolbar>
              <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                <MenuBookIcon
                  sx={{
                    fontSize: 32,
                    mr: 1,
                    color: theme.palette.primary.main,
                    mt: -0.5
                  }}
                />
                <Typography
                  variant="h5"
                  component={Link}
                  to={user ? "/dashboard" : "/"}
                  sx={{
                    color: theme.palette.primary.main,
                    textDecoration: "none",
                    fontWeight: 700,
                    letterSpacing: '-0.5px',
                    fontSize: "1.5rem"
                  }}
                >
                  AI Diary
                </Typography>
              </Box>
              {user && (
                <>
                  <Button
                    color="primary"
                    component={Link}
                    to="/dashboard"
                    sx={{
                      mx: 1,
                      '&:hover': {
                        backgroundColor: alpha(theme.palette.primary.main, 0.08)
                      }
                    }}
                  >
                    Dashboard
                  </Button>
                  <IconButton
                    component={Link}
                    to="/settings"
                    sx={{
                      color: theme.palette.primary.main,
                      mx: 1
                    }}
                  >
                    <SettingsIcon />
                  </IconButton>
                </>
              )}
              {user ? (
                <IconButton
                  onClick={() => auth.signOut()}
                  aria-label="Sign Out"
                  sx={{
                    color: theme.palette.primary.main,
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.08)
                    }
                  }}
                >
                  <LogoutIcon />
                </IconButton>
              ) : (
                <LoginButton auth={auth} />
              )}
            </Toolbar>
          </AppBar>
          <Container
            maxWidth="lg"
            sx={{
              flex: 1,
              py: 4,
              px: { xs: 2, sm: 3, md: 4 }
            }}
          >
            <Routes>
              <Route
                path="/"
                element={<Home storage={storage} user={user} />}
              />
              <Route
                path="/dashboard"
                element={
                  user ? (
                    <Dashboard user={user} storage={storage} db={db} />
                  ) : (
                    <Navigate to="/" replace />
                  )
                }
              />
              <Route path="/new" element={<Entry db={db} auth={auth} />} />
              <Route
                path="/entry/:id"
                element={<Entry db={db} auth={auth} />}
              />
              <Route path="/settings" element={<Settings user={user} />} />
              <Route path="/payment-success" element={<PaymentSuccess />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
            </Routes>
          </Container>
          <Footer />
        </Box>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
