import React from "react";
import { Box, Typography, Paper, Grid, Card, CardMedia } from "@mui/material";
import PaletteIcon from "@mui/icons-material/Palette";
import ExploreIcon from "@mui/icons-material/Explore";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SecurityIcon from "@mui/icons-material/Security";
import { styled } from "@mui/system";
import LoginButton from "./components/LoginButton";
import { getAuth } from "firebase/auth";
import teaserImage from "./images/teaser.png";

const ColorfulPaper = styled(Paper)(({ theme }) => ({
  background: "linear-gradient(45deg, #D84C6F 30%, #333 90%)",
  borderRadius: 15,
  padding: theme.spacing(4),
  color: "white",
  marginBottom: theme.spacing(4),
}));

const FeatureBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(2),
  backgroundColor: "rgba(255,255,255,0.2)",
  borderRadius: "50%",
  padding: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Home = ({ storage, user }) => {
  const auth = getAuth();

  const InfoBox = () => (
    <ColorfulPaper elevation={3} sx={{ mt: 4 }}>
      <Typography variant="h3" component="h1" gutterBottom fontWeight="bold">
        Welcome to AI Diary!
      </Typography>
      <Typography variant="h5" paragraph>
        Unlock the power of your thoughts with AI-driven journaling
      </Typography>
      <Typography variant="h6" paragraph>
        ✨ Currently free for everyone as we work on building the best product possible.
      </Typography>
      <Grid container spacing={4} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" paragraph>
            AI Diary is an innovative platform that uses AI to provide unique
            insights into your daily reflections.
          </Typography>
          <Typography variant="body1" paragraph>
            Our AI analyzes your entries, uncovering patterns and connections
            you might have missed, helping you gain deeper self-understanding.
          </Typography>
          <Typography variant="body1" paragraph>
            Apply lessons from past entries to current situations, fostering
            personal growth and self-awareness.
          </Typography>
          <Typography variant="body1" paragraph>
            The more you use AI Diary, the more personalized and valuable the
            insights become, adapting to your unique style.
          </Typography>
          <Typography variant="body1" paragraph>
            Embark on a journey of self-discovery with AI Diary as your
            companion in unlocking your potential.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom fontWeight="bold">
            Key Features
          </Typography>
          <FeatureBox>
            <IconWrapper>
              <PaletteIcon />
            </IconWrapper>
            <Typography>
              Intuitive and beautiful journaling interface
            </Typography>
          </FeatureBox>
          <FeatureBox>
            <IconWrapper>
              <ExploreIcon />
            </IconWrapper>
            <Typography>AI-powered insights and pattern recognition</Typography>
          </FeatureBox>
          <FeatureBox>
            <IconWrapper>
              <AccountCircleIcon />
            </IconWrapper>
            <Typography>Personalized growth recommendations</Typography>
          </FeatureBox>
          <FeatureBox>
            <IconWrapper>
              <SecurityIcon />
            </IconWrapper>
            <Typography>Private and secure personal journaling</Typography>
          </FeatureBox>
        </Grid>
      </Grid>
      <Box sx={{ mt: 4 }}>
        <Card elevation={6} sx={{ borderRadius: 2, overflow: 'hidden' }}>
          <CardMedia
            component="img"
            image={teaserImage}
            alt="AI Diary Teaser"
            sx={{
              width: '100%',
            }}
          />
        </Card>
      </Box>
      {!user && (
        <>
          <Typography variant="h6" sx={{ mt: 2 }} fontWeight="bold">
            Ready to begin? Log in or create an account:
          </Typography>
          <LoginButton
            auth={auth}
            sx={{ mt: 2, backgroundColor: "white", color: "black" }}
          />
        </>
      )}
    </ColorfulPaper>
  );

  return <InfoBox />;
};

export default Home;
