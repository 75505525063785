import React from 'react';
import { Box, Typography, Link } from '@mui/material';

function Footer() {
  return (
    <Box component="footer" sx={{ py: 3, px: 2, mt: 'auto', backgroundColor: '#eee' }}>
      <Typography variant="body2" color="text.secondary" align="center">
        © {new Date().getFullYear()} Wandering Ghost, LLC. All rights reserved.
        {' | '}
        <Link color="inherit" href="/about">
          About
        </Link>
        {' | '}
        <Link color="inherit" href="/terms">
          Terms
        </Link>
        {' | '}
        <Link color="inherit" href="/privacy">
          Privacy
        </Link>
        {' | '}
        <Link color="inherit" href="mailto:wanderingghostllc@gmail.com?subject=AI%20Diary%20Feedback">
          🚀 Give us feedback!
        </Link>
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <a href="https://www.producthunt.com/products/ai-diary-2?utm_source=badge-follow&utm_medium=badge&utm_souce=badge-ai&#0045;diary&#0045;2" target="_blank" rel="noreferrer">
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/follow.svg?product_id=630862&theme=light"
            alt="AI&#0032;Diary - Journal&#0032;Daily&#0046;&#0032;Get&#0032;AI&#0032;Insights&#0046; | Product Hunt"
            style={{ width: "250px", height: "54px" }}
            width="250"
            height="54"
          />
        </a>
      </Box>
    </Box>
  );
}

export default Footer;
