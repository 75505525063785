import React from 'react';
import { Typography, Box, List, ListItem, ListItemText } from '@mui/material';

function Privacy() {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5">
        Privacy Policy
      </Typography>

      <Typography variant="body1" mb={2} mt={2}>
        At AI Diary, your privacy is our top priority. This Privacy Policy explains how Wandering Ghost, LLC ("we," "us," or "our") collects, uses, and protects your personal information when you use AIDiary.us ("the Site"). We are committed to ensuring that your journal entries and personal information remain private and secure.
      </Typography>

      <Typography variant="h6" mb={2}>
        1. Information We Collect
      </Typography>
      <Typography variant="body1" mb={0}>
        We collect information in the following ways:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="- Information You Provide: When you create an account, we collect personal information such as your email address and any other details you voluntarily provide." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Journal Entries: The content you write in your journal is stored securely and is only accessible by you." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Automatic Information: We may collect information automatically when you interact with the Site, such as your IP address, browser type, and operating system. This helps us improve the experience and performance of AI Diary." />
        </ListItem>
      </List>

      <Typography variant="h6" mb={2}>
        2. How We Use Your Information
      </Typography>
      <Typography variant="body1" mb={0}>
        We use the information we collect to:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="- Provide, operate, and improve the Site." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Personalize your experience on AI Diary, such as by recommending content based on your interests." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Communicate with you, including sending notifications and responding to your inquiries." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Protect the security and integrity of the Site and prevent fraud or abuse." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Comply with legal obligations." />
        </ListItem>
      </List>

      <Typography variant="h6" mb={2}>
        3. Sharing Your Information
      </Typography>
      <Typography variant="body1" mb={2}>
        We do not sell, rent, or share your personal information or journal entries with third parties. Your journal contents are private and can only be accessed by you. We may share non-personal, aggregated information for analytical purposes, but this data cannot be used to identify you.
      </Typography>

      <Typography variant="h6" mb={2}>
        4. Cookies and Tracking Technologies
      </Typography>
      <Typography variant="body1" mb={0}>
        We use cookies and similar tracking technologies to enhance your experience on AI Diary. Cookies allow us to:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="- Remember your preferences and settings." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Analyze how you use the Site to improve performance." />
        </ListItem>
      </List>
      <Typography variant="body1" mb={2}>
        You can manage or disable cookies through your browser settings, but doing so may affect how the Site functions.
      </Typography>

      <Typography variant="h6" mb={2}>
        5. Data Security
      </Typography>
      <Typography variant="body1" mb={2}>
        We take reasonable precautions to protect your information. However, no online service can be 100% secure. While we strive to safeguard your data, we cannot guarantee the absolute security of your information.
      </Typography>

      <Typography variant="h6" mb={2}>
        6. Your Choices
      </Typography>
      <Typography variant="body1" mb={0}>
        You have control over your personal information on AI Diary. You can:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="- Access Your Data: You can access and export your journal entries at any time." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Delete Your Account: You can request the deletion of your account and all associated data by contacting us at wanderingghostllc@gmail.com. We will promptly delete all your journal entries and personal information from our systems." />
        </ListItem>
      </List>

      <Typography variant="h6" mb={2}>
        7. Children’s Privacy
      </Typography>
      <Typography variant="body1" mb={2}>
        AI Diary is not intended for users under the age of 13, and we do not knowingly collect personal information from children. If we discover that a child under 13 has provided us with personal information, we will delete it immediately.
      </Typography>

      <Typography variant="h6" mb={2}>
        8. Third-Party Links
      </Typography>
      <Typography variant="body1" mb={2}>
        AI Diary may contain links to third-party websites or services. We are not responsible for the privacy practices or content of those sites. We encourage you to review their privacy policies.
      </Typography>

      <Typography variant="h6" mb={2}>
        9. Changes to This Privacy Policy
      </Typography>
      <Typography variant="body1" mb={2}>
        We may update this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, provide additional notice. Your continued use of the Site means you accept the updated policy.
      </Typography>

      <Typography variant="h6" mb={2}>
        10. Contact Us
      </Typography>
      <Typography variant="body1" mb={2}>
        If you have any questions or concerns about this Privacy Policy, please contact us at:
      </Typography>
      <Typography variant="body1" mb={2}>
        Wandering Ghost, LLC<br />
        PO Box 362<br />
        Montague, MI, 49437<br />
        Email: wanderingghostllc@gmail.com
      </Typography>

      <Typography variant="body1" mb={2}>
        By using AI Diary, you agree to the collection and use of your information as outlined in this Privacy Policy. We are committed to maintaining the privacy and security of your personal journal.
      </Typography>
    </Box>
  );
}

export default Privacy;
