import React, { useState, useEffect } from "react";
import {
  Alert,
  CircularProgress,
  Typography,
  Box,
  Paper,
  Button,
  TextField,
} from "@mui/material";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import { Link } from "react-router-dom";

function Profile({ user }) {
  const [username, setUsername] = useState("");
  const [isEditingUsername, setIsEditingUsername] = useState(false);
  const [loading, setLoading] = useState(true);
  const [subscriptionLevel, setSubscriptionLevel] = useState(0);
  const [error, setError] = useState(null);
  const db = getFirestore();

  const stripeCustomerPortalUrl =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL
      : process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL_TEST;

  const stripePurchaseUrl =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_STRIPE_PURCHASE_URL
      : process.env.REACT_APP_STRIPE_PURCHASE_URL_TEST;

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const userDoc = await getDoc(doc(db, "users", user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUsername(userData.username || "");
        setSubscriptionLevel(userData.subscriptionLevel || 0);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError("Failed to fetch user data");
    } finally {
      setLoading(false);
    }
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleUsernameSubmit = async () => {
    if (username.trim()) {
      try {
        // Check if the username already exists
        const usernameQuery = await getDocs(
          query(
            collection(db, "users"),
            where("username", "==", username.trim())
          )
        );

        if (!usernameQuery.empty) {
          setError("Username already exists. Please choose a different one.");
          return;
        }

        // If username is unique, save it
        await setDoc(
          doc(db, "users", user.uid),
          { username: username.trim() },
          { merge: true }
        );
        setIsEditingUsername(false);
        setError(null); // Clear any previous errors
      } catch (error) {
        console.error("Error updating username:", error);
        setError("Failed to update username. Please try again.");
      }
    }
  };

  const handleSubscribe = () => {
    window.open(stripePurchaseUrl, "_blank", "noopener,noreferrer");
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Typography variant="h4" component="h1" gutterBottom>
        Settings
      </Typography>
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
        <Typography variant="h6" gutterBottom>
          Welcome, {username || user.displayName || "User"}!
        </Typography>
        {isEditingUsername ? (
          <Box mt={2}>
            <TextField
              label="Username"
              value={username}
              onChange={handleUsernameChange}
              variant="outlined"
              size="small"
            />
            <Button
              onClick={handleUsernameSubmit}
              variant="contained"
              sx={{ ml: 1 }}
            >
              Save
            </Button>
          </Box>
        ) : (
          <Box>
            <Button
              onClick={() => setIsEditingUsername(true)}
              variant="outlined"
              size="small"
              sx={{ mt: 1 }}
            >
              {username ? "Change Username" : "Set Username"}
            </Button>
          </Box>
        )}
        <Box mt={2} sx={{ display: "none" }}>
          <Typography variant="body1" gutterBottom>
            <Box>
              Subscription Level: {subscriptionLevel === 1 ? "Premium" : "Free"}
            </Box>
            {subscriptionLevel > 0 && (
              <Link to={stripeCustomerPortalUrl}>Manage Subscription</Link>
            )}
          </Typography>
          {subscriptionLevel === 0 && (
            <>
              <Alert severity="info" sx={{ mb: 2 }}>
                Upgrade to premium to unlock all the features! Just $10/mo.
              </Alert>
              <Button
                onClick={handleSubscribe}
                variant="contained"
                color="primary"
                sx={{ mt: 1 }}
              >
                Subscribe to Premium
              </Button>
            </>
          )}
        </Box>
      </Paper>
    </Box>
  );
}

export default Profile;
